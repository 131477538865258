import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonRefresher, IonRefresherContent, IonButtons, IonButton, IonIcon } from '@ionic/react';
import { refreshCircleOutline, personCircleOutline } from 'ionicons/icons';
import './IframePage.css';

interface ContainerProps {
  url: string;
}

const IframePage: React.FC<ContainerProps> = ({ url }) => {
  function refreshContent() {
      console.log("REFRESH");
      window.location.reload();
  }
    
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton href="https://accounts.google.com/AccountChooser/signinchooser?flowName=GlifWebSignIn&flowEntry=AccountChooser">
              <IonIcon slot="icon-only" icon={personCircleOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle><svg style={{height: '24px'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 355.8 101.79"><path fill="#ffffff" d="M0 3.85h37.69c19.67 0 33.84 14.31 33.84 33.7S57.36 71.12 37.69 71.12H19.26v29H0Zm37.69 48c9.22 0 14.58-6 14.58-14.31s-5.36-14.43-14.58-14.43H19.26v28.75ZM106.75 29.71c21 0 34 14.45 34 36.59v5H90.1l.14.27c2.2 9.22 10.45 13.21 18.43 13.21a29.28 29.28 0 0 0 19.54-7l7.56 15c-5.64 4.67-14.17 9.07-27.37 9.07-23.25 0-36.87-15.54-36.87-36s13.62-36.14 35.22-36.14Zm-.55 15.41c-8.67 0-14.45 4.54-16.24 12.93h31c-.87-7.43-5.96-12.93-14.76-12.93ZM175.5 29.71c12.25 0 21.19 3.72 27.38 10.18L192.7 53.1c-4.13-4.54-11.69-7-18.71-7-5.64 0-8.67 1.79-8.67 4.82 0 3.3 3.31 4 16 6.88 13.07 3 22 7.84 22 21.59 0 15.13-10.73 22.42-29.71 22.42-13.76 0-24.63-4.12-30.54-11.41l10.59-13.76c4.4 5.09 11.56 8.94 21.87 8.94 5.09 0 9.08-1.37 9.08-5.23 0-3.57-3.44-4.4-15.27-7-10.73-2.34-22.83-7-22.83-21.74-.03-14.06 10.97-21.9 28.99-21.9ZM239.08 100.83c-12.79 0-20.22-6-20.22-21.73V46.63h-12.52V31.36h12.52V12.93H237v18.43h17.47v15.27H237v29.85c0 4.41 2.2 6.19 6.87 6.19a56.72 56.72 0 0 0 10.05-1.1v17.2a65.21 65.21 0 0 1-14.84 2.06ZM259.51 11.69a11.63 11.63 0 1 1 23.25 0 11.63 11.63 0 0 1-23.25 0Zm20.63 88.45H262V31.36h18.15ZM321.82 29.71c21.05 0 34 14.45 34 36.59v5h-50.64l.14.27c2.2 9.22 10.45 13.21 18.43 13.21a29.26 29.26 0 0 0 19.53-7l7.57 15c-5.64 4.67-14.17 9.07-27.38 9.07-23.24 0-36.86-15.54-36.86-36s13.62-36.14 35.21-36.14Zm-.55 15.41c-8.66 0-14.44 4.54-16.23 12.93H336c-.83-7.43-5.92-12.93-14.73-12.93Z"/></svg></IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={refreshContent}>
              <IonIcon slot="icon-only" icon={refreshCircleOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonRefresher slot="fixed" onClick={refreshContent}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        <iframe title="main-iframe" scrolling="no" className="main-iframe" src={url} />
      </IonContent>
    </IonPage>
  );
};

export default IframePage;
