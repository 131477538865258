import IframePage from '../components/IframePage';

const Renewals: React.FC = () => {

  return (
    <IframePage url="https://datastudio.google.com/embed/reporting/d4a343d5-0595-4e0c-9344-034cf09f1a28/page/p_2qy2c7z0sc" />
  );
};

export default Renewals;
